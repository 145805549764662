define(["dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/4b24f1ca113a2b30eaaa04a45d6dd2d03e35cc06/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/4b24f1ca113a2b30eaaa04a45d6dd2d03e35cc06/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/when!/home/mcdeploy/mc_node_modules_cache/4b24f1ca113a2b30eaaa04a45d6dd2d03e35cc06/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (array, Deferred, when) {
  "use strict";

  // module:
  //		dojo/promise/all
  var some = array.some;
  return function all(objectOrArray) {
    // summary:
    //		Takes multiple promises and returns a new promise that is fulfilled
    //		when all promises have been resolved or one has been rejected.
    // description:
    //		Takes multiple promises and returns a new promise that is fulfilled
    //		when all promises have been resolved or one has been rejected. If one of
    //		the promises is rejected, the returned promise is also rejected. Canceling
    //		the returned promise will *not* cancel any passed promises.
    // objectOrArray: Object|Array?
    //		The promise will be fulfilled with a list of results if invoked with an
    //		array, or an object of results when passed an object (using the same
    //		keys). If passed neither an object or array it is resolved with an
    //		undefined value.
    // returns: dojo/promise/Promise

    var object, array;
    if (objectOrArray instanceof Array) {
      array = objectOrArray;
    } else if (objectOrArray && typeof objectOrArray === "object") {
      object = objectOrArray;
    }
    var results;
    var keyLookup = [];
    if (object) {
      array = [];
      for (var key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
          keyLookup.push(key);
          array.push(object[key]);
        }
      }
      results = {};
    } else if (array) {
      results = [];
    }
    if (!array || !array.length) {
      return new Deferred().resolve(results);
    }
    var deferred = new Deferred();
    deferred.promise.always(function () {
      results = keyLookup = null;
    });
    var waiting = array.length;
    some(array, function (valueOrPromise, index) {
      if (!object) {
        keyLookup.push(index);
      }
      when(valueOrPromise, function (value) {
        if (!deferred.isFulfilled()) {
          results[keyLookup[index]] = value;
          if (--waiting === 0) {
            deferred.resolve(results);
          }
        }
      }, deferred.reject);
      return deferred.isFulfilled();
    });
    return deferred.promise; // dojo/promise/Promise
  };
});